.projectCardLink {
  text-decoration: none;
}

.projectCardImgDiv {
  overflow: hidden;
  max-width: 450px;
  max-height: 300px;
  margin-bottom: 7px;
}

.projectCardImg {
  max-width: 450px;
  margin-bottom: 10px;
}

.projectCardTitle {
  text-decoration: none;
}

.projectCardViewProject {
  text-decoration: none;
}

@media only screen and (max-width: 550px) {
  .projectCardImg {
    width: 400px;
  }
}

@media only screen and (max-width: 500px) {
  .projectCardImg {
    width: 350px;
  }

  .projectCardTitle {
    font-size: 25px;
  }
}

@media only screen and (max-width: 400px) {
  .projectCardImg {
    width: 300px;
  }

  .projectCardTitle {
    font-size: 23px;
  }
}