.darkNavItems {
  color: #FFFFFF;
}

.darkNavItems:hover {
  color: lightblue;
}

.active.darkNavItems:hover {
  color: lightblue;
}

.dark {
  background-color: #212529;
  color: #FFFFFF;
}

.darkButton {
  background: none;
  color: #FFFFFF;
  border: none;
  font-size: 25px;
}

.darkButton:hover {
  color: lightblue;
}

.darkResumeButton {
  background-color: #f9fafb;
  border: none;
  border-radius: 5px;
  color: #000000E6;
  font-size: 20px;
  width: 100px;
  height: 50px;
}

.darkResumeButton:hover {
  background-color: #CFD8DC;
}

.darkprojectCardTitle,
.darkprojectCardViewProject {
  color: #FFFFFF;
}

.darkContactSubmitButton {
  background-color: #f9fafb;
  border: none;
  border-radius: 5px;
  color: #000000E6;
  font-size: 20px;
  max-width: 300px;
}

.darkContactSubmitButton:hover {
  background-color: #CFD8DC;
}

.darkProjectNavBackArrow {
  color: #FFFFFF;
}

.darkProjectNavBackArrow:hover {
  color: lightblue;
}

.darkContactFormInput {
  border: 2px solid #FFFFFF;
}

.darkContactFormTextArea {
  border: 2px solid #FFFFFF;
}

.darkViewSiteLink,
.darkViewCodeLink {
  color: #FFFFFF;
  text-decoration: none;
}

.darkViewSiteLink:hover {
  color: lightblue;
  text-decoration: underline;
}

.darkViewCodeLink:hover {
  color: lightblue;
  text-decoration: underline;
}

.darkCardMotionDiv {
  border: 3px solid #000000E6;
  border-radius: 5px;
  box-shadow: 10px 10px 10px #FFFFFF;
  background-color: #000000E6;
  padding: 10px;
  height: 410px;
}

@media only screen and (max-width: 550px) {
  .darkCardMotionDiv {
    height: 390px;
  }
}

@media only screen and (max-width: 500px) {
  .darkCardMotionDiv {
    height: 350px;
  }
}

@media only screen and (max-width: 400px) {
  .darkCardMotionDiv {
    height: 320px;
  }
}

@media (prefers-color-scheme: dark) {
  .darkNavItems {
    color: #FFFFFF;
  }

  .darkNavItems:hover {
    color: lightblue;
  }

  .active.darkNavItems:hover {
    color: lightblue;
  }

  .dark {
    background-color: #212529;
    color: #FFFFFF;
  }

  .darkButton {
    background: none;
    color: #FFFFFF;
    border: none;
    font-size: 25px;
  }

  .darkButton:hover {
    color: lightblue;
  }

  .darkResumeButton {
    background-color: #f9fafb;
    border: none;
    border-radius: 5px;
    color: #000000E6;
    font-size: 20px;
    width: 100px;
    height: 50px;
  }

  .darkResumeButton:hover {
    background-color: #CFD8DC;
  }

  .darkprojectCardTitle,
  .darkprojectCardViewProject {
    color: #FFFFFF;
  }

  .darkContactSubmitButton {
    background-color: #f9fafb;
    border: none;
    border-radius: 5px;
    color: #000000E6;
    font-size: 20px;
    max-width: 300px;
  }

  .darkContactSubmitButton:hover {
    background-color: #CFD8DC;
  }

  .darkProjectNavBackArrow {
    color: #FFFFFF;
  }

  .darkProjectNavBackArrow:hover {
    color: lightblue;
  }

  .darkContactFormInput {
    border: 2px solid #FFFFFF;
  }

  .darkContactFormTextArea {
    border: 2px solid #FFFFFF;
  }

  .darkViewSiteLink,
  .darkViewCodeLink {
    color: #FFFFFF;
    text-decoration: none;
  }

  .darkViewSiteLink:hover {
    color: lightblue;
    text-decoration: underline;
  }

  .darkViewCodeLink:hover {
    color: lightblue;
    text-decoration: underline;
  }

  .darkCardMotionDiv {
    border: 3px solid #000000E6;
    border-radius: 5px;
    box-shadow: 10px 10px 10px #FFFFFF;
    background-color: #000000E6;
    padding: 10px;
    height: 410px;
  }  

  @media only screen and (max-width: 550px) {
    .darkCardMotionDiv {
      height: 390px;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .darkCardMotionDiv {
      height: 350px;
    }
  }
  
  @media only screen and (max-width: 400px) {
    .darkCardMotionDiv {
      height: 320px;
    }
  }
}