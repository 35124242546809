.lightNavItems {
  color: #000000E6;
}

.lightNavItems:hover {
  color: #4B626D;
}

.active.lightNavItems:hover {
  color: #4B626D;
}

.light {
  background-color: #F8F9FA;
  color: #000000E6;
}

.lightButton {
  background: none;
  color: #000000E6;
  border: none;
  font-size: 25px;
}

.lightButton:hover {
  color: #4B626D;
}

.lightResumeButton {
  background-color: #4C556C;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 20px;
  width: 100px;
  height: 50px;
}

.lightprojectCardTitle,
.lightprojectCardViewProject {
  color: #000000E6;
}

.lightContactSubmitButton {
  background-color: #4C556C;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 20px;
  max-width: 300px;
}

.lightProjectNavBackArrow {
  color: #000000E6;
}

.lightProjectNavBackArrow:hover {
  color: #4B626D;
}

.lightContactFormInput {
  border: 2px solid #000000E6;
}

.lightContactFormTextArea {
  border: 2px solid #000000E6;
}

.lightViewSiteLink,
.lightViewCodeLink {
  color: #000000E6;
  text-decoration: none;
}

.lightViewSiteLink:hover {
  color: #4B626D;
  text-decoration: underline;
}

.lightViewCodeLink:hover {
  color: #4B626D;
  text-decoration: underline;
}
.lightCardMotionDiv {
  border: 3px solid #000000E6;
  border-radius: 5px;
  box-shadow: 10px 10px 10px #000000E6;
  background-color: #FFFFFF;
  padding: 10px;
  height: 410px;
  max-width: 500px;
}

@media only screen and (max-width: 550px) {
  .lightCardMotionDiv {
    height: 390px;
  }
}

@media only screen and (max-width: 500px) {
  .lightCardMotionDiv {
    height: 350px;
  }
}

@media only screen and (max-width: 400px) {
  .lightCardMotionDiv {
    height: 320px;
  }
}

@media (prefers-color-scheme: light) {
  .lightNavItems {
    color: #000000E6;
  }

  .lightNavItems:hover {
    color: #4B626D;
  }

  .active.lightNavItems:hover {
    color: #4B626D;
  }

  .light {
    background-color: #F8F9FA;
    color: #000000E6;
  }
  
  .lightButton {
    background: none;
    color: #000000E6;
    border: none;
    font-size: 25px;
  }

  .lightButton:hover {
    color: #4B626D;
  }

  .lightResumeButton {
    background-color: #4C556C;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 20px;
    width: 100px;
    height: 50px;
  }

  .lightResumeButton:hover {
    background-color: #444c61;
  }
  
  .lightprojectCardTitle,
  .lightprojectCardViewProject {
    color: #000000E6;
  }

  .lightContactSubmitButton {
    background-color: #4C556C;
    border: none;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 20px;
    max-width: 300px;
  }

  .lightContactSubmitButton:hover {
    background-color: #444c61;
  }

  .lightProjectNavBackArrow {
    color: #000000E6;
  }

  .lightProjectNavBackArrow:hover {
    color: #4B626D;
  }

  .lightContactFormInput {
    border: 2px solid #000000E6;
  }

  .lightContactFormTextArea {
    border: 2px solid #000000E6;
  }

  .lightViewSiteLink,
  .lightViewCodeLink {
    color: #000000E6;
    text-decoration: none;
  }

  .lightViewSiteLink:hover {
    color: #4B626D;
    text-decoration: underline;
  }

  .lightViewCodeLink:hover {
    color: #4B626D;
    text-decoration: underline;
  }

  .lightCardMotionDiv {
    border: 3px solid #000000E6;
    border-radius: 5px;
    box-shadow: 10px 10px 10px #000000E6;
    background-color: #FFFFFF;
    padding: 10px;
    height: 410px;
  }

  @media only screen and (max-width: 550px) {
    .lightCardMotionDiv {
      height: 390px;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .lightCardMotionDiv {
      height: 350px;
    }
  }
  
  @media only screen and (max-width: 400px) {
    .lightCardMotionDiv {
      height: 320px;
    }
  }
}