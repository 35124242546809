.portfolioHero {
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.hiThere {
  margin-bottom: 0;
}

.heroCursor {
  font-size: 2.5rem;
}

.Typewriter__wrapper {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.portfolioHeroSocialIcons {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.gitHubLogo,
.linkedinLogo {
  height: 50px;
}

@media only screen and (max-width: 991px) {
  .portfolioHero {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin: 30px 0;
  }
}

@media only screen and (max-width: 500px) {
  .hiThere {
    font-size: 2.1rem;
  }
  
  .heroCursor {
    font-size: 2.1rem;
  }
  
  .Typewriter__wrapper {
    font-size: 2.1rem;
  }
}