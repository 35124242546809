.certificatesContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 30px;
}

.certificatesTitle {
  padding: 15px 0;
}

.certificatesDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.AWSCertificateImageDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.certificatesDiv > p {
  margin: 0;
}

.certificate {
  width: 250px;
  height: 190px;
}