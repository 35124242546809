.projectsDiv {
  display: flex;
  flex-direction: column;
}

.projectsTitle {
  padding: 15px 0;
}

.projectsListDiv {
  display: flex;
  justify-content: space-evenly;
  gap: 30px;
  flex-wrap: wrap;
}