.navBar {
  padding: 10px 10px 0 10px;
}

.navItems {
  margin-left: auto;
  text-align: center;
  display: flex;
  align-items: baseline;
}

@media only screen and (max-width: 991px) {
  .navItems {
    align-items: center;
  }
}